import React, { Component } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';

import CiMenu from "../components/conversational-index/usa/ci-menu/ci-menu";
import CiFooterFr from "../components/conversational-index/usa/ci-footer/ci-footer"
import CiGDPRFr from "../components/conversational-index/usa/ci-gdpr/ci-methodo";


import SEO from "../components/seo";


class GDPRPage extends Component {

    render(){

        return(
            <>
                <SEO title="Conversational Maturity Index : Évaluez la maturité conversationnelle de votre entreprise." image="https://info.iadvize.com/hubfs/CI%20-%202021/Thumbnail%20-%20Conversational%20Index-1.png" lang="fr" description="En réalisant la version en ligne de cet audit, obtenez un aperçu objectif de votre maturité conversationnelle en moins de 10 minutes. Recevez une analyse présentant vos forces et vos axes d’amélioration, ainsi que votre situation par rapport à votre secteur."/>
                <CiMenu/>
                
                
                <CiGDPRFr/>
                
                
                <CiFooterFr/>
            </>
        )
    }

}

export default GDPRPage