import React from "react"

import CiMethodoStyle from "./ci-methodo.module.scss";

const CiGDPRFr = (props) => {

        return <section className={ CiMethodoStyle.methodo }>
            <div className={ CiMethodoStyle.inner }>
            <h3>Privacy</h3>
            <div className={ CiMethodoStyle.content }>
                <span>Data protection policy + Cookies policy</span>
                <br/><br/>
                Last updated : March 8th, 2021
                <br/><br/>
                <strong>Data protection policy </strong><br/><br/>
                <span>1. Data controller and recipients of personal data</span><br/><br/>
                We inform you that your personal data collected more generally as part of the relationship that you might have with our company, is processed by iAdvize SAS (“iAdvize”) based in Euronantes Gare, Le Berlingot bât. B, 9 Rue Nina Simone, 44000 Nantes, France.
The data collected is intended to be used by iAdvize. It will also be made available to our technical service providers (“sub-contractors” as defined in the regulations), for the strict purposes of their designated task.
Access is strictly governed by the security policies in place so that human intervention with the data remains highly exceptional. In addition, such access is governed by typical contractual clauses pursuant to the requirements of the regulations for data transfers outside of the EU.
                <br/><br/>
                <span>2. Legal basis and purposes for collecting personal data</span><br/><br/>
                When some of your data is essential for us to fulfill a contract to which you or your company are parties, or to meet a regulatory obligation, we tell you when collecting it.
                Your data will be used in different ways, dependent on your reason for entering into a relationship with our company:<br/>

                    - For the organisation of promotional operation, based on your consent, which you can withdraw at any point without negating the initial legality of use of your data;<br/>
                    - For the requirements of the performance of your contract or a contract concluded with the company you represent (delivery of services, invoicing, monitoring and performance of the contract, delivery, after-sales service and claims, customer satisfaction surveys, customer file management, management of non-payments and disputes);<br/>
                    - For processing and responding to requests that could give rise to a contractual relationship (request for information);<br/>
                    - For meeting our legal, accounting, and fiscal obligations (particularly in preserving accounting supporting documents, managing requests for rights, rectification and objection, keeping a list of objections to market research);<br/>
                    - For meeting our legitimate needs, unless you object, and within the limits of your interests and rights. This includes, in particular: Marketing campaigns, customer relationship monitoring, and customer loyalty ; Managing people’s opinions of our services or content with the aim of improving the products and services offered by our company; Creating commercial statistics in order to analyse our company’s sales activity ; Management of the prospective customer file in order to develop our company’s sales activity.<br/>
                <br/><br/>
                <span>3. Rights of data subjects</span><br/><br/>
                You have a right to access and rectify erroneous data concerning you, and, on a case by case basis and according to the limits established by the regulations, to object with and erase some of your data, restrict its use, or request its portability with a view to its transmission to a third party.
                To exercise your rights, simply write to us at privacy@iadvize.com and attach, if applicable, proof of identity and a document supporting your request.
                <br/><br/>

                <span>4. Retention of personal data</span><br/><br/>
                Your data will be kept for a period that is necessary for the above-mentioned purposes. Data enabling customer relationship management will be kept for 3 years after the end of the commercial relationship.  Data collected for commercial prospecting will also be kept for 6 months after collection.
                At the end of these periods, the data will be deleted from active databases and, if necessary, archived for a duration not exceeding the legally prescribed periods or the applicable archiving obligations. Once these periods have expired, the data will be destroyed.<br/><br/> 

                <span>5. Mandatory or optional data provision</span><br/><br/>
                Data that is essential to iAdvize for the purposes described above are indicated by an asterisk on the contact form. If you do not fill in the mandatory fields, we will not be able to follow up your requests and/or provide the desired services.
                Data that is not marked by an asterisk is optional; it enables us to know you better and improve both our contact with you and the services we provide you.<br/><br/>

                <span>6. Contacting the Data Protection Office</span><br/><br/>
                For any additional information or difficulty concerning the collection or processing of your data, you can contact our Data Protection Officer (DPO) at privacy@iadvize.com .
                If you have any unresolved issues, you can contact a supervisory authority.
                
                <br/><br/>
                <strong>DATA RECIPIENTS</strong>
                <br/><br/>

                In the context of providing our services, we may send your data, or provide access to it, to our departments and our technical sub-contractors.
                iAdvize may have to send your Data to third parties if such a disclosure is authorised and required by law or a legal decision, or if this disclosure is necessary for the protection and defence of its rights.
                Outside of these cases, your Data will not be sold or made accessible to any third party without your prior agreement.
                iAdvize never transfers your Data to countries outside the EEA. In the event of transfer outside the EEA, such transfers will be covered by the appropriate guarantees, such as the standard clauses required by the European Commission.

                <br/><br/>
                <strong>YOUR RIGHTS</strong>
                <br/><br/>
                You have a right of access to data concerning you, a right to request rectification, erasure or portability, as well as a right to request restriction of the processing of your Data and to withdraw your consent.
                You also have the option of objecting, at any point :
                These rights can be exercised at any time by sending an e-mail to our DPO: privacy@iadvize.com ;
                or a letter to:<br/><br/>

                iAdvize (Legal)<br/>
                Euronantes Gare, Le Berlingot bât. B<br/>
                9 Rue Nina Simone<br/>
                44000 Nantes, France<br/>
                <br></br><br></br>
                You also have the right to make a complaint to a supervisory authority.
                The French supervisory authority is the Commission nationale de l’Informatique et des Libertés (CNIL), 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.
                
                <br/><br/>

                <strong>Cookies policy</strong><br/><br/>
                This section is dedicated to our cookie management policy.
                <br></br>
                It will allow you to learn more about the origin and use of the browsing information processed during your browsing of the website (hereinafter referred to as the “Website”), and your rights.
                <br></br><br></br>
                <strong>What are cookies?</strong><br></br>
                The CNIL [the French National Commission on Informatics and Liberty] (www.cnil.fr) defines cookies as follows:
<br></br>    
“A cookie is a piece of information deposited on to your hard drive from the server of the website you are visiting. The cookie’s purpose is to collect information relating to your browsing and to provide you with content and services that suit your interests. It contains several pieces of information: the name of the server that deposited it, an identifier in the form of a unique number, and possibly a cookie expiration date, etc.   
This information is sometimes stored on your computer in the form of a simple text file that a server accesses in order to read and save information.”
<br></br>
Specifically, during your visit to the Website, “cookies” can be saved onto your device (computer, tablet, smartphone, etc.).
A cookie is a small stored text file containing a list of characters that are downloaded to your device during your visit. The cookies are then sent back to the Website during each of your subsequent visits, or to another site recognising these cookies.<br></br><br></br>
                <strong>Cookies on the Website</strong><br></br>
                The Website’s cookies are used in the context of audience measurement for the Website, and potentially in the context of identification data.<br></br><br></br>
                <strong>Audience measurement cookies</strong><br></br>
                These cookies are used to establish volumetry and statistics regarding how frequently the Website is used, the different sections visited, and the elements used.<br></br><br></br>
                <strong>Cookie use management</strong><br></br>
                Cookies are stored for 12 months maximum. This period is not extended through further visits.
                The deposit of the cookies on your device assumes your consent. Therefore, upon arrival on the Website, an information banner indicates to you that we are using this technology and that in continuing to browse, you accept the deposit of cookies onto your device. You can nevertheless change cookie preferences at any time by clicking on the following link: <a href="javascript:Didomi.preferences.show()">Manage cookies</a>.<br></br><br></br>
                <strong>Disabling cookies</strong><br></br>
                Disabling cookies may make it impossible to fully benefit from all or part of the Website’s functionalities.<br></br><br></br>
                <strong>How to change cookie settings with your browser?</strong><br></br>
                Each web browser’s cookie settings are different. They are described in the help menu of your web browser and will let you know how to change your preferences regarding cookies.
                <br></br>
                Here are the help pages for the most popular browsers :
                <br></br><br></br>
                Internet Explorer™: http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies<br></br>
                Safari™: http://docs.info.apple.com/article.html?path=Safari/3.0/fr/9277.html<br></br>
                Chrome™: http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647<br></br>
                Firefox™: https://support.mozilla.org/fr/kb/activer-desactiver-cookies<br></br>
                Opera™: http://help.opera.com/Windows/10.20/fr/cookies.html”<br></br><br></br>
            </div>
            <hr></hr>

            </div>
        </section>
  
}

export default CiGDPRFr



