import React, { useState } from "react";
import { Modal, Button, Toast } from 'react-bootstrap';

import { Link } from 'gatsby';

import CiFrStyle from "./ci-menu.module.scss";

import "./custom.scss";

const CiMenu = (props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [email, setEmail] = useState("");
    const [industrie, setIndustrie] = useState("");
    const [website, setWebsite] = useState("");
    const [job, setJob] = useState("");

    const [showToast, setShowToast] = useState(false);
    const toggleToast = () => setShowToast(!showToast);

    const handleEmail = (event) => setEmail(event.target.value);
    const handleIndustrie = (event) => setIndustrie(event.target.value);
    const handleWebsite = (event) => setWebsite(event.target.value);
    const handleJob = (event) => setJob(event.target.value);

    const sendDataToHubspot = () => {
        console.log("le mail est : " + email);
        console.log("l'industrie est : " + industrie);
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"fields":[{"name":"email","value":email},{"name":"consent_checkbox","value":"true"},{"name":"jobtitle","value":job},{"name":"website","value":website},{"name":"ci_industry","value":industrie}],"context":{"pageUri":"www.conversational-index.com/fr/","pageName":"Conversational Index - Landing Page - Form menu"}}),
            redirect: 'follow'
        };
        fetch('https://api.hsforms.com/submissions/v3/integration/submit/403167/1f945562-c2ee-4649-b6ed-c592b3d41b02', requestOptions)
        .then(response => response.json())
        .then(result => console.log(result))
        .then(handleClose)
        .then(toggleToast)
        .catch(error => console.log('error', error));
    }

    return <section className={ CiFrStyle.menu }>
                <div className={ CiFrStyle.inner } >
                    <a href="https://www.conversational-maturity-index.com/en">
                        <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Property%201=Primaire.png" alt="" />
                    </a>  
                    <div className={ CiFrStyle.right } >
                        <Button className={ CiFrStyle.cta } id={ CiFrStyle.toTeam } onClick={handleShow} >
                            Entrust your evaluation to our teams
                        </Button>
                        <Link className={ CiFrStyle.cta } id={ CiFrStyle.startEvaluation } to={"questionnaire"}>Start the test</Link>
                    </div>
                </div>

                <div
                    style={{
                        position: 'fixed',
                        top: 10,
                        right: 10,
                        zIndex: 99
                    }}
                >
                <Toast show={showToast} onClose={toggleToast} delay={5000} autohide>
                    <Toast.Header>
                        <strong>Thank you!</strong>
                    </Toast.Header>
                    <Toast.Body>You will receive an email with your area and areas of improvement.</Toast.Body>
                </Toast>

                </div>

                <Modal
                className="modalMain"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                >
                <Modal.Body className="modalBody">
                    <img onClick={handleClose} src="https://info.iadvize.com/hubfs/CI%20-%202021/Group%20865.png"></img>
                    <h3 className="modalTitle">Entrust</h3>
                    <p className="modalLegend">your evaluation to our teams</p>

                    <div className="formInner">
                        <form action="" method="get" className="formLate">

                            <div className="formSet">
                                <span>Industry*</span>
                                <select id="industries" name="industries" value={industrie} onChange={handleIndustrie} required>
                                    <option value="Retail & CPG">Retail & CPG</option>
                                    <option value="Finance - Health Insurance">Finance - Health Insurance</option>
                                    <option value="Automotive">Automotive</option>
                                    <option value="Telco & Consumer Services">Telco & Consumer Services</option>
                                    <option value="Travel & Tourism">Travel & Tourism</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>

                            <div className="formSet">
                                <span>Job*</span>
                                <input type="text" name="job" id="job" value={job} onChange={handleJob} placeholder="Job title" required/>
                            </div>

                            <div className="formSet">
                                <span>Website*</span>
                                <input type="text" name="website" id="website" value={website} onChange={handleWebsite} placeholder="website" required/>
                            </div>

                            <div className="formSet">
                                <span>Email adress*</span>
                                <input type="email" name="email" id="email" value={email} onChange={handleEmail} placeholder="Professional email*" required/>
                            </div>

                            <div className="formSet">
                                <div className="rgpd">
                                    <input type="checkbox" id="scales" name="scales" required/>
                                    <label>I have read and accept <a href="https://privacy.iadvize.com/fr/">the iAdvize privacy policy.</a></label>
                                </div>
                            </div>

                            <div className="formSet">
                                <Button id="goToLead" className="ctaFinal" onClick={sendDataToHubspot} disabled={!email || !website || !industrie }>
                                    Entrust your evaluation to our teams
                                </Button>
                            </div>

                        </form>
                    </div>

                </Modal.Body>
                </Modal>


            </section>

}

export default CiMenu
